import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { useIntl } from "gatsby-plugin-react-intl"

const TestimonialsPage = ({ data: { page, bgImage } }) => {
  const intl = useIntl()
  const pluginImage = getImage(bgImage.childImageSharp.gatsbyImageData)
  const image = convertToBgImage(pluginImage)

  return (
    <Layout>
      <h1 className="hidden">{page?.title}</h1>
      <Seo seoData={page?.seo} />
      <BackgroundImage
        Tag="div"
        // Spread bgImage into BackgroundImage:
        {...image}
        preserveStackingContext
        id="ContactPage"
        className="flex items-center justify-center bg-fixed bg-black bg-opacity-50 bg-bottom bg-no-repeat bg-blend-multiply"
      >
        <h2 className="py-20 text-5xl italic font-bold text-center text-white lg:text-6xl font-PTSerif">
          {intl.formatMessage({ id: "What our clients say" })}
        </h2>
      </BackgroundImage>
      <div className="flex py-10">
        <iframe
          src="https://apps.elfsight.com/widget/49fed2d9-f02a-4086-9791-fb9e6ebb8128"
          width="100%"
          height="1800"
          frameBorder="0"
          title="Pomares Law Group Google Reviews"
        ></iframe>

        {/* <script src="https://apps.elfsight.com/p/platform.js" defer></script>
        <div class="elfsight-app-af9eddf5-6fb6-4192-b22a-d3e059ceca8a"></div> */}
      </div>
    </Layout>
  )
}

export default TestimonialsPage

export const pageQuery = graphql`
  query TestimonialsPageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      slug
      seo {
        fullHead
        schema {
          raw
        }
      }
    }
    bgImage: file(
      name: { regex: "/-banner/" }
      relativeDirectory: { eq: "contact" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          width: 1400
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`
